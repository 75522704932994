
document.addEventListener('DOMContentLoaded', function() {
    const toggles = document.querySelectorAll('.toggle');
    toggles.forEach(toggle => {
        toggle.addEventListener('click', function(event) {
            event.preventDefault();
            const answer = this.closest('.accordion_item').querySelector('.answer');
            if (answer.style.display === 'none' || !answer.style.display) {
                answer.style.display = 'block';
            } else {
                answer.style.display = 'none';
            }
        });
    });
});
document.addEventListener('DOMContentLoaded', function() {
    const flexWrappers = document.querySelectorAll('.flex_wrapper');
    const steps = document.querySelectorAll('.step');
    const headers = document.querySelectorAll('h3');
    const videos = document.querySelectorAll('.yt_video');

    function removeActiveClass(elements) {
        elements.forEach(element => {
            element.classList.remove('active');
        });
    }

    function addClickListener(elements) {
        elements.forEach(element => {
            element.addEventListener('click', function() {
                const wrapper = this.closest('.flex_wrapper');
                const index = wrapper.getAttribute('data-index');
                
                // Activate the clicked flex wrapper and deactivate others
                removeActiveClass(flexWrappers);
                wrapper.classList.add('active');
                
                // Show the corresponding video and hide others
                removeActiveClass(videos);
                document.getElementById('video_index_' + index).classList.add('active');
            });
        });
    }

    jQuery(document).ready(function($) {
        $('.read-more-btn').on('click', function() {
            var content = $(this).prev('.content');
            
            if (content.hasClass('expanded')) {
                content.removeClass('expanded');
                $(this).text('Read More');
            } else {
                content.addClass('expanded');
                $(this).text('Read Less');
            }
        });
    });
    
    jQuery(document).ready(function($) {
        var $video = $('#client-video');
        var $playIcon = $('#play-icon-review');
    
        // Hide play icon on click and play video
        $playIcon.on('click', function() {
            $video.get(0).play();
            $(this).hide(); // Hide the play icon immediately on click
        });
    
        // Ensure play icon is hidden when video starts playing
        $video.on('play', function() {
            $playIcon.hide();
        });
    
        // Show play icon if video is paused and not at the end
        $video.on('pause', function() {
            if ($video.get(0).currentTime < $video.get(0).duration) {
                $playIcon.show();
            }
        });
    
        // Optional: ensure the play icon doesn't reappear when video ends
        $video.on('ended', function() {
            $playIcon.hide(); // or keep hidden
        });
    });
    

    jQuery(document).ready(function($) {
        $('.play-button').on('click', function() {
            var $videoItem = $(this).closest('.video-item');
            
            // Hide the placeholder and play button
            $videoItem.find('.video-placeholder').hide();
            $(this).hide();
            
            // Show the video embed
            var $videoEmbed = $videoItem.find('.video-embed');
            $videoEmbed.show();
            
            // If the video is an iframe, start playing it (works for YouTube/Vimeo)
            var $iframe = $videoEmbed.find('iframe');
            if ($iframe.length) {
                var src = $iframe.attr('src');
                $iframe.attr('src', src + (src.includes('?') ? '&' : '?') + 'autoplay=1');
            }
        });
    });

    jQuery(document).ready(function($) {
        function initCarousel() {
            if ($(window).width() < 768) {
                $('.featured-carousel').not('.slick-initialized').slick({
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    autoplay: true,
                    dots: false,
                    arrows:false
                });
            } else {
                if ($('.featured-carousel').hasClass('slick-initialized')) {
                    $('.featured-carousel').slick('unslick');
                }
            }
        }
    
        // Initialize on page load and resize
        initCarousel();
        $(window).resize(function() {
            initCarousel();
        });
    });
    jQuery(document).ready(function($) {
        // Function to handle showing video and hiding placeholder
        function handleVideoClick() {
            $('.video-placeholder').on('click', function() {
                var $videoContainer = $(this).closest('.video-container');
                var $video = $videoContainer.find('video');
    
                // Show the video and hide the placeholder
                $video.removeClass('hidden').get(0).play();
                $(this).hide(); // Hide the placeholder
            });
        }
    
        // Call the function
        handleVideoClick();
    
        // Load more testimonials functionality
        $('#load-more-testimonials').on('click', function() {
            $('.testimonial-item:hidden').slice(0, 4).slideDown();
            if ($('.testimonial-item:hidden').length === 0) {
                $(this).fadeOut();
            }
        });
    });
    
    jQuery(document).ready(function($) {
        $('.show-more').on('click', function(e) {
            e.preventDefault();
            var $this = $(this);
            var $fullContent = $this.siblings('.full-content');
            var $shortContent = $this.siblings('.short-content');
            
            if ($fullContent.is(':visible')) {
                $fullContent.hide();
                $shortContent.show();
                $this.text('Show More');
            } else {
                $fullContent.show();
                $shortContent.hide();
                $this.text('Show Less');
            }
        });
    });
    jQuery(document).ready(function($) {
        $('.show-more').on('click', function() {
            $(this).siblings('.full-content').toggle(); // Toggle hidden content
            $(this).text($(this).text() === 'Show More' ? 'Show Less' : 'Show More'); // Toggle button text
        });
    
        $('#view-more-blogs').on('click', function() {
            $('.blog-item.hidden').removeClass('hidden'); // Show all hidden blogs
            $(this).hide(); // Hide the "View more blogs" button
        });
    });
    jQuery(document).ready(function($) {
        // Target each testimonial's video and play icon individually
        $('.testimonial-item').each(function() {
            var $video = $(this).find('video');
            var $playIcon = $(this).find('.play-icon',);
            var $placeholder = $(this).find('.video-placeholder');
            var $videoContainer = $(this).find('.video-container');
    
            function playVideo(event) {
                event.preventDefault();
                $placeholder.css('opacity', 0); // Fade out placeholder
                $video.removeClass('hidden').addClass('visible'); // Fade in video
                $video.get(0).play(); // Play the video
                $playIcon.addClass('hidden'); // Hide the play icon
            }
    
            $videoContainer.on('click', playVideo);
    
            $video.on('pause', function() {
                $playIcon.removeClass('hidden');
            });
        });
    });

 
    jQuery(document).ready(function($) {
        // Handle Show More button clicks
        $('.show-more').click(function() {
            $(this).siblings('.full-content').slideToggle();
            $(this).text($(this).text() === 'Show More' ? 'Show Less' : 'Show More');
        });
    
        // Handle View More Blogs button click
        $('#view-more-blogs').click(function() {
            $('.blog-item.hidden').removeClass('hidden');
            $(this).hide();
        });
    });
    
    jQuery(document).ready(function($) {
        // Initially hide all videos beyond the first 6
        $('.video-item').each(function(index) {
            if (index >= 6) {
                $(this).addClass('hidden');
            }
        });
    
        // Load more button click event
        $('#load-more').on('click', function() {
            var hiddenVideos = $('.video-item.hidden').slice(0, 6);
            hiddenVideos.removeClass('hidden');
    
            // If no more hidden videos, hide the button
            if ($('.video-item.hidden').length === 0) {
                $(this).hide();
            }
        });
    });

    jQuery(document).ready(function($) {
        var $video = $('#client-video');
        var $playIcon = $('#play-icon-review');
        var $placeholder = $('#video-placeholder');
    
        function playVideo() {
            $placeholder.css('opacity', 0); // Fade out placeholder
            $video.removeClass('hidden').addClass('visible'); // Fade in video
            $video.get(0).play(); // Play the video
            $playIcon.addClass('hidden'); // Hide the play icon
        }
    
        $playIcon.on('click', playVideo);
        $placeholder.on('click', playVideo);
    
        $video.on('play', function() {
            $playIcon.addClass('hidden');
            $placeholder.css('opacity', 0); // Ensure placeholder is hidden after video starts
        });
    
        $video.on('pause', function() {
            $playIcon.removeClass('hidden');
        });
    });
    
    
    

	jQuery(document).ready(function($) {
        if ($('.accordion').length) {
            var allPanels = $('.accordion dd').hide();
    
            $('.accordion_item').click(function(event) {
                event.preventDefault();
    
                var accordionItem = $(this);
    
                if (accordionItem.hasClass('active')) {
                    accordionItem.removeClass('active');
                    $(this).removeClass('active');
                    $(this).find('dd').slideUp();
                } else {
                    $('.accordion_item').removeClass('active');
                    $('.accordion dt > a').removeClass('active');
                    allPanels.slideUp();
    
                    accordionItem.addClass('active');
                    $(this).addClass('active');
                    $(this).find('dd').slideDown();
                }
            });
        }
    });
    

    function moveVideosToFlexWrapper() {
        if (window.innerWidth <= 768) {
            videos.forEach(video => {
                const index = video.getAttribute('data-index');
                const targetWrapper = document.querySelector(`.flex_wrapper[data-index="${index}"] .inner_content`);
                if (targetWrapper) {
                    targetWrapper.appendChild(video);
                }
            });
        }
    }

    addClickListener(steps);
    addClickListener(headers);

    // Move videos into flex_wrapper on load and on resize
    moveVideosToFlexWrapper();
    window.addEventListener('resize', moveVideosToFlexWrapper);
});

jQuery(document).ready(function() {
    function debounce(func, delay) {
        let debounceTimeout;
        return function(...args) {
            clearTimeout(debounceTimeout);
            debounceTimeout = setTimeout(() => func.apply(this, args), delay);
        };
    }

    jQuery('.postcast_slider').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        infinite: true,
        speed: 300,
        arrows: true,
        prevArrow: '.prev',
        nextArrow: '.next',
        //autoplay: true,
        //autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    jQuery('.prev').click(debounce(function() {
        jQuery('.postcast_slider').slick('slickPrev');
    }, 500));

    jQuery('.next').click(debounce(function() {
        jQuery('.postcast_slider').slick('slickNext');
    }, 500));
});


// jQuery(document).ready(function($) {
//     // Hide all dd elements initially
//     $('.accordion dd').hide();

//     // Click event for dt > a, dt .icon, and dt > h4 elements
//     $('.accordion').on('click', 'dt > a, dt .icon, dt > h4', function(e) {
//         e.preventDefault();

//         var $accordionItem = $(this).closest('.accordion_item');

//         if ($accordionItem.hasClass('active')) {
//             // If already active, remove active class and hide content
//             $accordionItem.removeClass('active').find('dd').slideUp();
//         } else {
//             // Remove active class from all accordion items and hide their content
//             $('.accordion_item').removeClass('active').find('dd').slideUp();

//             // Add active class to clicked item and show its content
//             $accordionItem.addClass('active').find('dd').slideDown();
//         }
//     });
// });

  
jQuery(document).ready(function() {
    jQuery('.quotes_slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        infinite: true,
        speed: 300,
        asNavFor: '.slider-nav',
        arrows: true,
        //autoplay: true,
        //autoplaySpeed: 2000,
        prevArrow: '.button-prev',
        nextArrow: '.button-next',
    });

    jQuery('.slider-nav').slick({
        slidesToShow: Math.min(6, jQuery('.slider-nav .slide').length),
        slidesToScroll: 1,
        asNavFor: '.quotes_slider',
        infinite: true,
        centerMode: false,
        focusOnSelect: true,
        arrows: false,
        dots: false,
        variableWidth: true
    });

    jQuery(document).on('click', '.video-thumbnail', function() {
        var videoContainer = jQuery(this).parent('.video-container');
        var embedUrl = videoContainer.data('embed-url');
        var iframe = '<iframe width="100%" height="100%" src="' + embedUrl + '" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        videoContainer.html(iframe);
    });
});